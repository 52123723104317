import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import clsx from "clsx";

//MUI
import { styled } from "@mui/material/styles";

import ButtonControl from "../OpenLayersExt/ButtonControl";
import makeMUIControlStyle from "../makeMUIControlStyle";

import MapContext from "@/components/Map/MapContext";

import { geoLocateStyle } from "@/components/Map/mapStyles";

//Types
import { IGeoLocateControl } from "@/@types/components/Map/Controls/Custom";

//OpenLayers
import OlGeolocation from 'ol/Geolocation';
import OlFeature from 'ol/Feature';
import OlGeomPoint from 'ol/geom/Point';
import OlVectorSource from 'ol/source/Vector';
import OlVectorLayer from 'ol/layer/Vector';
import { Geometry } from "ol/geom";

const GeoLocateControl = (props: IGeoLocateControl) => {
  const mapContext = useContext(MapContext);
  const [active, setActive] = useState(false);

  const { tooltip, handleClick, target, className } = props;

  const map = mapContext?.map;
  let geolocationRef = useRef<OlGeolocation | null>(null);
  let layerRef = useRef<OlVectorLayer<OlVectorSource<Geometry>> | null>(null);

  const handleGeoLocate = useCallback(() => {
    setActive(prev => !prev);
  }, [handleClick]);

  useEffect(() => {

    const geolocation = new OlGeolocation({
      trackingOptions: {
        enableHighAccuracy: true
      },
      projection: map?.getView().getProjection()
    })

    const accuracyFeature = new OlFeature();
    geolocation.on('change:accuracyGeometry', function () {
      const poly = geolocation.getAccuracyGeometry();
      if (poly) {
        accuracyFeature.setGeometry(poly);
      } else {
        accuracyFeature.setGeometry(undefined);
      }
    });

    const positionFeature = new OlFeature();
    positionFeature.setStyle(geoLocateStyle);

    geolocation.on('change:position', function () {
      const coordinates = geolocation.getPosition();
      positionFeature.setGeometry(coordinates ? new OlGeomPoint(coordinates) : undefined);
    });

    geolocationRef.current = geolocation;

    const geolocateLayer = new OlVectorLayer({
      source: new OlVectorSource({
        features: [accuracyFeature, positionFeature],
      }),
      zIndex: 9999,
    });

    layerRef.current = geolocateLayer;
    map?.addLayer(geolocateLayer);

  }, [])

  useEffect(() => {
    geolocationRef.current?.setTracking(active);
    if (active) {
      const coordinates = geolocationRef.current?.getPosition();
      if (coordinates) {
        const view = map?.getView();
        view?.setCenter(coordinates);
      }
      if (layerRef.current) {
        map?.addLayer(layerRef.current);
      }
    } else {
      if (layerRef.current) {
        map?.removeLayer(layerRef.current);
      }
    }
  }, [active])

  return (
    <ButtonControl
      id="geolocate-btn"
      className={className}
      title={tooltip}
      html={`<i class="${ active ? 'fas fa-map-marker-alt': 'fas fa-map-marker'}"></i>`}
      handleClick={handleGeoLocate}
      target={target}
    />
  );
}

const StyledGeoLocateControl = styled(GeoLocateControl)(({theme}) => {
  const styles = makeMUIControlStyle(theme);
  return {
    ...styles.control
  }
})

export default StyledGeoLocateControl;
