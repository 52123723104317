import { createContext } from "react";

//Types
import Control from "ol/control/Control";

export type BarControlContextType = {
  exists: boolean
  childControls: Control[]
  controlsHash: string;
  addChildControl: (c: Control) => void;
  removeChildControl: (c: Control) => void;
  removeChildControlById: (id: string) => void;
}


const BarControlContext = createContext<BarControlContextType>({
  exists: false,
  childControls: [],
  controlsHash: "",
  addChildControl: (c: Control) => {},
  removeChildControl: (c: Control) => {},
  removeChildControlById: (id: string) => {}
});

BarControlContext.displayName = "BarControlContext";

export default BarControlContext;
