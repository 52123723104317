import { FunctionComponent, useContext, useEffect } from "react";
import clsx from "clsx";

//MUI
import { useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";

//Openlayers
import ScaleLine from "ol/control/ScaleLine";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlControl } from "@/lib/olHelpers";
import makeMUIControlStyle from "../makeMUIControlStyle";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IScaleLineControl } from "@/@types/components/Map/Controls/OpenLayers";

const ScaleLineControl: FunctionComponent<IScaleLineControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    className: undefined,
    minWidth: undefined,
    render: undefined,
    target: undefined,
    units: undefined,
    bar: undefined,
    steps: undefined,
    text: undefined
  };

  const events = {
    change: undefined,
    "change:units": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => {
    const overridenProps = {
      ...props
      , className: clsx(props.className, "ol-scale-line")
    }
    registerOlControl(context, ScaleLine, overridenProps, options, events)
  }
    , [
      props.className,
      props.minWidth,
      props.render,
      props.target,
      props.units,
      props.bar,
      props.steps,
      props.text
    ]);

  return null;
};

const StyledScaleLineControl = styled(ScaleLineControl)(({theme}) => {
  const styles = makeMUIControlStyle(theme);
  return {
    ...styles.scaleline,
    ...styles.sidebarSticky
  }
})

export default StyledScaleLineControl;
