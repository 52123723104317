import { transform } from "ol/proj";

import OlStyle from "ol/style/Style";
import OlStroke from "ol/style/Stroke";
import OlCircle from "ol/style/Circle";
import OlFill from "ol/style/Fill";
import OlIcon from "ol/style/Icon";
import OlText from "ol/style/Text";
import OlRegularShape from "ol/style/RegularShape";

import OlGeomMultiPoint from "ol/geom/MultiPoint";
import OlGeomPoint from "ol/geom/Point";
import OlGeomPolygon from "ol/geom/Polygon";
import OlGeomLineString from "ol/geom/LineString";
import {toStringHDMS} from 'ol/coordinate';
import {createStringXY} from 'ol/coordinate';

import { calcAngle ,formatArea, formatLength } from "@/lib/olHelpers";

export {
  drawStyle,
  measurementsStyle,
  measuringStyle,
  recordStyle,
  selectedStyle,
  geoLocateStyle,
  drawingRouteStyle,
  drawRouteStyle,
  routeStyle,
  routePointStyle
};


const recordStyle = () => {
  let style = new OlStyle({
    image: new OlCircle({
      radius: 7,
      stroke: new OlStroke({
        color: "rgba(255, 255, 255, 0.79)"
      }),
      fill: new OlFill({
        color: "rgba(26, 114, 158, 0.79)"
      })
    }),
    fill: new OlFill({
      color: "rgba(184, 184, 184, 0.4)"
    }),
    stroke: new OlStroke({
      color: "#ff1f1f",
      width: 3.25
    })
  });
  return style;
};

const selectedStyle = () => {
  let style = new OlStyle({
    image: new OlCircle({
      radius: 7,
      stroke: new OlStroke({
        color: "rgba(255, 255, 255, 0.79)"
      }),
      fill: new OlFill({
        color: "rgba(26, 114, 158, 0.79)"
      })
    }),
    fill: new OlFill({
      color: "rgba(184, 184, 184, 0.4)"
    }),
    stroke: new OlStroke({
      color: "#ff1f1f",
      width: 3.25
    })
  });
  return style;
};

const drawStyle = new OlStyle({
  image: new OlCircle({
    radius: 7,
    stroke: new OlStroke({
      color: "rgba(255, 255, 255, 0.79)"
    }),
    fill: new OlFill({
      color: "rgba(26, 114, 158, 0.79)"
    })
  }),
  fill: new OlFill({
    color: "rgba(255,255,255,0.4)"
  }),
  stroke: new OlStroke({
    color: "#3399CC",
    width: 3.25
  })
});

const measuringStyle = new OlStyle({
  fill: new OlFill({
    color: 'rgba(255, 255, 255, 0.2)',
  }),
  stroke: new OlStroke({
    color: 'rgba(0, 0, 0, 0.5)',
    lineDash: [10, 10],
    width: 4,
  }),
  image: new OlCircle({
    radius: 5,
    stroke: new OlStroke({
      color: 'rgba(0, 0, 0, 0.5)',
      width: 4
    }),
    fill: new OlFill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
  })
});

// @Piero
// not sure what f is at the moment (maybe layer)
const measurementsStyle = (f:any) => {
  const geom = f.getGeometry();

  var output;
  var tooltipCoord;

  if (geom instanceof OlGeomPolygon) {
    output = formatArea(geom);
    //tooltipCoord = geom.getInteriorPoint().getCoordinates();
    tooltipCoord = geom.getCoordinates()[0];
  } else if (geom instanceof OlGeomLineString) {
    output = formatLength(geom);
    tooltipCoord = geom.getCoordinates()[0];
  } else if (geom instanceof OlGeomPoint) {
    let htrs_array = transform(geom.getCoordinates(), "EPSG:3857", "EPSG:3765");
    output = "X: "+htrs_array[0].toFixed(0) + ", Y:" + htrs_array[1].toFixed(0);
    tooltipCoord = geom;
  }
  let style = [
    new OlStyle({
      image: new OlCircle({
        radius: 5,
        stroke: new OlStroke({
          color: "#232f34",
          width: 4
        }),
        fill: new OlFill({
          color: "rgba(255, 255, 255, 0.2)"
        })
      })
    }),
    new OlStyle({
      fill: new OlFill({
        color: "rgba(255, 255, 255, 0.5)"
      }),
      stroke: new OlStroke({
        color: "#232f34",
        lineDash: [10, 10],
        width: 4,
      })
    }),
    new OlStyle({
      text: new OlText({
        font: "12px sans serif",
        fill: new OlFill({ color: "#000" }),
        stroke: new OlStroke({color: "#fff", width: 8}),
        text: output,
        textBaseline: "middle",
        offsetY: -12
      })
    })
    // ,
    // new OlStyle({
    //   image: new OlCircle({
    //     radius: 3,
    //     stroke: new OlStroke({
    //       color: "#232f34"
    //     }),
    //     fill: new OlFill({
    //       color: "rgba(255, 255, 255, 0.2)"
    //     })
    //   }),
    //   geometry: function (feature) {
    //     // return the coordinates of the first ring of the polygon
    //     const geom = feature.getGeometry();
    //     const type = geom.getType();
    //     var coordinates = geom.getCoordinates
    //       ? type === "Polygon"
    //         ? geom.getCoordinates()[0]
    //         : geom.getCoordinates()
    //       : null;
    //     return coordinates ? new OlGeomMultiPoint(coordinates) : null;
    //   }
    // })
  ];
  return style;
};

const geoLocateStyle = new OlStyle({
  image: new OlCircle({
    radius: 6,
    fill: new OlFill({
      color: '#3399CC',
    }),
    stroke: new OlStroke({
      color: '#fff',
      width: 2,
    })
  })
});

const drawingRouteStyle = (f: any) => {

  const color = "rgba(255, 0, 255, 0.8)";
  const fillColor = "rgba(255,0,255, 0.5)";

  const pointStyle = new OlStyle({
    image: new OlCircle({
      radius: 6,
      stroke: new OlStroke({
        color: color,
        width: 4
      })
    })
  });

  const lineStyle = new OlStyle({
    fill: new OlFill({
      color: fillColor
    }),
    stroke: new OlStroke({
      color: color,
      lineDash: [15, 15],
      width: 4
    })
  });

  const pointsOnLineStyle = new OlStyle({
    image: new OlCircle({
      radius: 6,
      stroke: new OlStroke({
        color: color,
        width: 4
      })
    }),
    geometry: function (feature) {
      // return the coordinates of the first ring of the polygon
      const geom = feature.getGeometry();
      if (geom && geom instanceof OlGeomLineString) {
        const coordinates = geom.getCoordinates();
        // coordinates.shift(); //remove first
        // coordinates.pop(); //remove last
        return new OlGeomMultiPoint(coordinates);
      } else {
        return undefined;
      }
    }
  });

  return [pointStyle, lineStyle, pointsOnLineStyle];
};

const drawRouteStyle = (f: any) => {
  const geom = f.getGeometry();
  const coords = geom.getCoordinates();
  const p1 = coords.length > 0 ? coords[0] : null;
  const p2 = coords.length >= 2 ? coords[1] : null;

  const color = "rgba(255, 0, 255, 0.8)";
  const fillColor = "rgba(255,0,255, 0.5)";

  const pointStyle = new OlStyle({
    image: new OlCircle({
      radius: 6,
      stroke: new OlStroke({
        color: color,
        width: 4
      })
    })
  });

  const lineStyle = new OlStyle({
    fill: new OlFill({
      color: fillColor
    }),
    stroke: new OlStroke({
      color: color,
      lineDash: [15, 15],
      lineDashOffset: 15,
      width: 4
    })
  });

  const pointsOnLineStyle = new OlStyle({
    image: new OlCircle({
      radius: 6,
      stroke: new OlStroke({
        color: color,
        width: 4
      })
    }),
    geometry: function (feature) {
      // return the coordinates of the first ring of the polygon
      const geom = feature.getGeometry();
      if (geom && geom instanceof OlGeomLineString) {
        const coordinates = geom.getCoordinates();
        coordinates.shift(); //remove first
        // coordinates.pop(); //remove last
        return new OlGeomMultiPoint(coordinates);
      } else {
        return undefined;
      }
    }
  });

  const firstPointOnLineStyle = new OlStyle({
    image: new OlRegularShape({
      points: 3,
      radius: 16,
      stroke: new OlStroke({
        color: color,
        width: 4
      }),
      rotation: calcAngle(p1, p2),
      rotateWithView: true
    }),
    geometry: function (feature) {
      // return the coordinates of the first ring of the polygon
      const geom = feature.getGeometry();
      if (geom && geom instanceof OlGeomLineString) {
        const coordinates = geom.getCoordinates();
        return coordinates.length > 0 ? new OlGeomPoint(coordinates[0]) : undefined;
      } else {
        return undefined;
      }
    }
  });

  const lastPointOnLineStyle = new OlStyle({
    image: new OlCircle({
      radius: 14,
      stroke: new OlStroke({
        color: color,
        width: 4
      }),
    }),
    geometry: function (feature) {
      // return the coordinates of the first ring of the polygon
      const geom = feature.getGeometry();
      if (geom && geom instanceof OlGeomLineString) {
        const coordinates = geom.getCoordinates();
        return coordinates.length > 0 ? new OlGeomPoint(coordinates[coordinates.length - 1]) : undefined;
      } else {
        return undefined;
      }
    }
  });

  return [pointStyle, lineStyle, firstPointOnLineStyle, pointsOnLineStyle, lastPointOnLineStyle];
};

const routeStyle = (f: any) => {
  const code = f.get("code");
  const color = code === "A" ? "#ff00FF" : code === "B" ? "#0000FF" : code === "C" ? "#00FF00" : "#ff1f1f"
  const outer = new OlStyle({
    stroke: new OlStroke({
      color: "rgba(255,0,0,0.8)",
      width: 9
    })
  });
  const inner = new OlStyle({
    stroke: new OlStroke({
      color: "rgba(255,255,255,1)",
      width: 3
    })
  });
  return [ outer, inner ];
};

const routePointStyle = (f: any) => {
  const color = 'rgba(255,0,0,0.8)'
  return new OlStyle({
    image: new OlCircle({
      radius: 6,
      stroke: new OlStroke({
        color: color,
        width: 4
      }),
      fill: new OlFill({
        color: "rgba(255,255,255,1)"
      })
    })
  });
}