import React, { useEffect, useState } from "react";
import clsx from "clsx";

//MUI
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Collapse, useTheme } from "@mui/material";

//MUI Icons
import LayersIcon from "@mui/icons-material/Layers"

//OpenLayers
import OlBaseLayer from "ol/layer/Base";

export type BaseLayerSwitcherUIProps = {
  className?: string;
  layers: Array<OlBaseLayer>
}

const BaseLayerSwitcherUI = React.forwardRef<HTMLDivElement, BaseLayerSwitcherUIProps>((props, ref) => {
  const theme = useTheme()

  const styles = {
    Box: {
      bgcolor: theme.palette.background.paper, 
      width: 70,
      marginTop: "3px",
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: theme.palette.background.paper,
      borderRadius: '5px'
    },
    layerBox: {
      width: 66,
      '&:hover': {
        ".image-container": {
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: theme.palette.primary.main
        },
        a: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: theme.palette.primary.main,
          borderRadius: '5px'
        }
      },
    },
    layerBoxSelected: {
      width: 66,
      ".image-container": {
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: theme.palette.primary.main
      },
      a: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
        borderRadius: '5px'
      }
    }
  }

  const { className, layers } = props;

  const [selectedLayer, setSelectedLayer] = useState<null | string>(null)
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    layers.forEach((l) => {
      if(l.getVisible()) setSelectedLayer(l.getProperties().id)
    })
  }, [])

  const handleClick = (evt: React.MouseEvent<HTMLElement>) => {
    setOpen(!open)
  }

  return (
    <div ref={ref} id="base-layer-switcher" 
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className={clsx(className, "ol-unselectable", "ol-control")} 
      style={{top: "3em", right: "0.5em", width: 70, height: 70}}
    >
      <Button onClick={handleClick} style={{width: "100%", height: "100%"}}>
        <LayersIcon style={{width: 40, height: 40}} />
      </Button>
      <Collapse in={open}>
        <Box sx={styles.Box} >
          <Stack spacing={1}>
            {layers.map((l, index, array) => 
              <Box 
                key={index}
                textAlign={"center"} 
                sx={l.getProperties().id === selectedLayer ? styles.layerBoxSelected : styles.layerBox} 
                onClick={() => {
                array.map((v) => v.setVisible(false))
                l.setVisible(!l.getVisible())
                setSelectedLayer(l.getProperties().id)
              }}>
                <Box className={"image-container"} sx={{height: 66, width: 66}}>
                  <img src={l.getProperties().preview} style={{width: 62}} />
                </Box>
                <a style={{fontSize: 14}}>{l.getProperties().title}</a>
              </Box>
            )}
          </Stack>
        </Box>
      </Collapse>
    </div>
  );
});

export default BaseLayerSwitcherUI;
