import React, { Fragment, FunctionComponent } from 'react';
import { useTranslation } from "react-i18next";
// import { Box, Typography } from "@mui/material";
// import { useTheme } from "@mui/material";

import { ILegendPane } from "@/@types/components/MapSidebarPanes";
import { Box, Typography, useTheme } from '@mui/material';
import gsService from '@/services/gsService';
import { findLayer, flattenLayers } from "@/lib/olHelpers";


const LegendPane: FunctionComponent<ILegendPane> = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme() as any;
  
    // @ts-ignore
    const { layersCollection } = props;
  
    const flattenedLayers = flattenLayers(layersCollection.getArray())

    const bgColor = theme.palette.background.default.substring(1);
    const fontColor = theme.palette.mode == "light" ? "000000" : "FFFFFF";

    console.log('LAYERS', layersCollection);
  console.log("Flattened",flattenedLayers[0].getProperties());
    return (
      <Fragment>
        { layersCollection ?
          flattenedLayers.map((layer: any, i: number) => 
            layer.getProperties().legend ?
              <Box key={i}>
                <Typography variant="h6">
                  {t(layer.getProperties().legend_ttoken)}
                </Typography>
                <img 
                  src={gsService.getLegend(layer.getProperties(), i18n.language, bgColor, fontColor)}
                />
              </Box>
            : null 
        ) : null }
      </Fragment>
    );
  };
  
  export default LegendPane;