import { IModel } from "@/@types/models/model";

import { coreFields } from "./helpers/coreFields";

const POI: IModel = {
  title: "POI",
  apiPath: "poi",
  forms : {
    "default": {
      fields: ["naziv", "vrsta", "adresa"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "naziv", "vrsta", "adresa"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "naziv",
      source: "naziv",
      ttoken: "common.naziv",
      type: "text",
      readonly: true
    },
    {
      title: "vrsta",
      source: "vrsta",
      ttoken: "common.vrsta",
      type: "text",
      readonly: true
    },
    {
      title: "adresa",
      source: "adresa",
      ttoken: "common.adresa",
      type: "text",
      readonly: true
    }
  ]
}

export default POI;
