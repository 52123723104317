import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

//Custom components
import { LayerSwitcherImageControl, BaseLayerSwitcherControl } from "@/components/Map/Controls";
import mapService from "@/services/mapService";
import MapContext from "@/components/Map/MapContext";

//OL
import OlBaseLayer from "ol/layer/Base";
import OlVectorLayer from "ol/layer/Vector";
import OlVectorSource from "ol/source/Vector";
import OlLayerGroup from "ol/layer/Group";
import Collection from 'ol/Collection';

//Types
import { MapContextType } from "@/@types/context/MapContext";

type GeoBaseLayerSwitcherProps = {
  allowNoLayer?: boolean;
}

function GeoBaseLayerSwitcher(props: GeoBaseLayerSwitcherProps) {
  const [baselayersGroup, setBaselayersGroup] = useState<OlLayerGroup | undefined>(undefined);
  const context: MapContextType | null = useContext(MapContext);
  const { t } = useTranslation();
  const { allowNoLayer } = props;

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    }

  }, []);

  useEffect(() => {
    if (context?.map) {
      mapService.getBaseLayers(t).then((olGroup: OlLayerGroup)=> {
        if (olGroup) {
          if (allowNoLayer) {
            const coll = olGroup.getLayers();
            const solidColorLayer = new OlVectorLayer({
              source: new OlVectorSource({}),
              // background: "#F00"
            })
            solidColorLayer.set("id", "base-solid");
            solidColorLayer.set("title", "N/A");
            solidColorLayer.set("type", "base");
            solidColorLayer.set("baseLayer", true);
            solidColorLayer.setVisible(false);

            coll.insertAt(0, solidColorLayer);
            olGroup.setLayers(coll);
          }
          const mapLayers = context?.map?.getLayers();
          if (mapLayers) {
            mapLayers.insertAt(0, olGroup);
          }
        }

        if (mounted.current) {
          setBaselayersGroup(olGroup);
        }
      });
    }
  }, [context?.map]);

  const showBaseLayerSwitcher =
    context?.map &&
    baselayersGroup && baselayersGroup.getLayers && baselayersGroup.getLayers().getArray().length > 0 ? true : false;

  const layersArray = baselayersGroup ? baselayersGroup.getLayers().getArray() : [];

  return showBaseLayerSwitcher ? (
    <BaseLayerSwitcherControl layers={layersArray} />
    // <LayerSwitcherImageControl id="base-layer-switcher" layerGroup={baselayersGroup} />
  ) : null;
}

export default GeoBaseLayerSwitcher;
