import React from "react";
import { useTranslation } from "react-i18next";

//Custom
import { flattenLayers, getCallDirectLayers, getCallGroupedLayers, getOnlyGroupLayers } from "@/lib/olHelpers";
import { GroupLayer } from "@/components/Map/Layers";
import GeoLayer from "./GeoLayer";
import GeoLayerCombined from "./GeoLayerCombined";

//OL
import OlCollection from "ol/Collection";
import OlBaseLayer from "ol/layer/Base";
import OlLayerGroup from "ol/layer/Group";

type GeoAPILayersProps = {
  layersCollection: OlCollection<OlBaseLayer>;
};

function GeoAPILayers(props: GeoAPILayersProps) {
  const { t } = useTranslation();

  const { layersCollection } = props;

  return layersCollection ? (
    <>
      {getOnlyGroupLayers(layersCollection.getArray()).map((layer: OlLayerGroup, i: number) => {
        return (
          // <GroupLayer key={layer.get("id") + "-" + i} id={layer.get("id")} title={t(layer.get("title"))}>
            <>
            {
             getCallDirectLayers(layer.getLayers().getArray()).map((cl: OlBaseLayer, j: number) => {
              return <GeoLayer key={j} id={cl.get("id")} layer={cl} />;
            })}
            {getCallGroupedLayers(layer.getLayers().getArray()).map((cgl, k: number) => {
              return (
                <GeoLayerCombined key={k} id={cgl.call_group} layersCollection={new OlCollection(cgl.layers)} />
              );
            })}
            </>
          // </GroupLayer>
        );
      })}
      {getCallDirectLayers(layersCollection.getArray()).map((l: OlBaseLayer, x: number) => {
        return <GeoLayer key={x} id={l.get("id")} layer={l} />;
      })}
      {getCallGroupedLayers(layersCollection.getArray()).map((gl, y: number) => {
        return <GeoLayerCombined key={y} id={gl.call_group} layersCollection={new OlCollection(gl.layers)} />;
      })}
    </>
  ) : null;
}

export default GeoAPILayers;
