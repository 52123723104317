import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import Splash from "./Splash";

import './styles/layout.css';
import './styles/map.css';
import './styles/splash-screen.css';

import i18n from "./i18n";

import * as dotenv from 'dotenv';

dotenv.config();

const root = createRoot(document.getElementById("root"))

root.render(<Splash />);

i18n.on('loaded', function(loaded) {
	setTimeout(() => {
		root.render(<App/>);
	}, 500);
})
