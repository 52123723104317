import React from 'react';

//Types
import { Theme } from "@mui/material";

// Control Style
const makeMUIControlStyle = (theme: Theme) => {
  return {
    control: {
      "& > button": {
        border: 0,
        backgroundColor: theme.palette.primary.light
      },
      "& > button:hover": {
        border: 0,
        backgroundColor: theme.palette.primary.dark
      },
      "& > button:focus": {
        border: 0,
        backgroundColor: theme.palette.primary.main
      }
    },
    sidebarSticky: {
      marginLeft: 46,
      "@media (min-width: 768px)": {
        transition: "margin-left 500ms",
      },
      "@media (min-width: 768px) and (max-width: 991px)": {
        marginLeft: 317

      },
      "@media (min-width: 992px) and (max-width: 1199px)": {
        marginLeft: 402
      },
      "@media (min-width: 1200px)": {
        marginLeft: 472
      },
      ".sidebar-map .sidebar-left.collapsed ~ .ol-viewport .ol-zoom": {
        marginLeft: 52
      },
      "&:nth-of-type(1)": {
        marginLeft: "unset"
      }
    },
    scaleline: {
      backgroundColor: theme.palette.primary.light
    }
  }
};

export default makeMUIControlStyle;