import React from "react";
import { useTranslation } from "react-i18next";

import GISPage from "@/ui/AppPage/GISPage";
import MainMap from "./MainMap";

function MapPage() {

  return (
    <GISPage>
      <MainMap />
    </GISPage>
  );
}

export default MapPage;
