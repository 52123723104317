import { FunctionComponent, useContext, useEffect } from "react";
import clsx from "clsx";

//MUI


import { styled } from "@mui/material/styles";

//OpenLayers ext
import Button from "ol-ext/control/Button";

//Custom components
import BarControlContext, { BarControlContextType} from "./Bar/BarControlContext";
import MapContext from "@/components/Map/MapContext";
import makeMUIControlStyle from "../makeMUIControlStyle";
import { findControl, getDefinedOptions, getEvents } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { Options as ButtonOptions } from "ol-ext/control/Button";


export interface IButtonControl extends ButtonOptions {
  id: string
  order?: number
}

const ButtonControl: FunctionComponent<IButtonControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;
  const parentBarContext = useContext(BarControlContext) as BarControlContextType;

  const options = {
    className: undefined,
    title: undefined,
    name: undefined,
    html: undefined,
    handleClick: undefined,
    target: undefined
  };

  const events = {};

  useEffect(() => {
    let allOptions = Object.assign(options, props);
    let definedOptions = getDefinedOptions(allOptions);

    let control = new Button(definedOptions);

    if(props.id) {
      control.set("id", props.id)
    }

    if (props.order !== undefined) {
      control.set("order", props.order);
    }

    if (parentBarContext && parentBarContext.exists) {
      parentBarContext.addChildControl(control);

    } else if (context.map) {
      const mapControl = findControl(context.map, props.id, Button);
      if (mapControl) {
        context.map.removeControl(mapControl);
      }
      context.map.addControl(control);
    } else {
      context.initOptions.controls.push(control);
    }

    let olEvents = getEvents(events, props);
    for (let eventName in olEvents) {
      //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
      control.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (context.map) {
        const mapControl = findControl(context.map, props.id, Button);
        if (mapControl) {
          context.map.removeControl(mapControl);
          if (parentBarContext.exists) {
            parentBarContext.removeChildControl(mapControl);
          }
        }
      }
    }

  }, [
    props.className,
    props.name,
    props.handleClick
  ])

  useEffect(() => {
    if (context.map) {
      const mapControl = findControl(context.map, props.id, Button);
      if (mapControl) {
        //@ts-ignore
        mapControl.setTitle(props.title);
      }
    }
  }, [props.title])

  useEffect(() => {
    if (context.map) {
      const mapControl = findControl(context.map, props.id, Button);
      if (mapControl) {
        //@ts-ignore
        mapControl.setHtml(props.html);
      }
    }
  }, [props.html])

  return null;
};

const StyledButtonControl = styled(ButtonControl)(({theme}) => {
  const styles = makeMUIControlStyle(theme);
  return {
    ...styles.control
  }
});

export default StyledButtonControl;
