import * as React from "react";
import { blue, green, grey } from "@mui/material/colors";
import { FunctionComponent } from "react";

//Material UI Core
import { ThemeProvider, createTheme, Theme } from "@mui/material/styles";

//Custom components
import AppPage from "@/ui/AppPage/AppPage";
import menuGIS from "@/routes/menuGIS";

//Types
import { IGISPage } from "@/@types/ui/AppPage";

const GISPage: FunctionComponent<IGISPage> = (props) => {

  return (
    <ThemeProvider theme={outerTheme => ({
      ...outerTheme,
      palette: {
        ...outerTheme.palette,
        primary: gisTheme.palette.primary,
        secondary: gisTheme.palette.secondary
      }

    })}>
      <AppPage modul="gis">
        {props.children}
      </AppPage>
    </ThemeProvider>
  );
};

const gisTheme : Theme = createTheme({
  palette: {
    primary: {
      main: green[800],
      dark: green[900],
      light: green[700]
    },
    secondary: {
      main: blue[800],
      dark: blue[900],
      light: blue[400]
    }
  }
});

export default GISPage;

