import React from "react";

//Types
export type ButtonGroupContextType = {
    isInGroup: boolean;
    groupId: string;
    element?: React.MutableRefObject<HTMLDivElement | null>;
  }

const ButtonGroupContext = React.createContext<ButtonGroupContextType>({
    isInGroup: false,
    groupId: "",
    element: undefined
})

export default ButtonGroupContext;