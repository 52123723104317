import { FunctionComponent, useContext, useEffect } from "react";
import clsx from "clsx";

//MUI
import { styled } from "@mui/material/styles";

import FullScreen from "ol/control/FullScreen";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlControl } from "@/lib/olHelpers";
import makeMUIControlStyle from "../makeMUIControlStyle";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IFullScreenControl } from "@/@types/components/Map/Controls/OpenLayers";

const FullScreenControl: FunctionComponent<IFullScreenControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const defaultLabelHtmlElement = document.createElement('i')
  defaultLabelHtmlElement.setAttribute('class', 'fas fa-expand-alt');

  const defaultLabelActiveHtmlElement = document.createElement('i')
  defaultLabelActiveHtmlElement.setAttribute('class', 'fas fa-compress-alt');

  const options = {
    className: undefined,
    label: defaultLabelHtmlElement,
    labelActive: defaultLabelActiveHtmlElement,
    activeClassName: "ol-full-screen-true",
    inactiveClassName: "ol-full-screen-false",
    keys: undefined,
    target: undefined,
    source: undefined
  };

  const events = {
    change: undefined,
    enterfullscreen: undefined,
    error: undefined,
    leavefullscreen: undefined,
    propertychange: undefined
  };

  useEffect(() => {
    registerOlControl(context, FullScreen, props, options, events)
  }
    , [
      props.className,
      props.label,
      props.labelActive,
      props.activeClassName,
      props.inactiveClassName,
      props.tipLabel,
      props.keys,
      props.target,
      props.source
    ]);

  return null;
};

const StyledFullScreenControl = styled(FullScreenControl)(({ theme }) => {
  const styles = makeMUIControlStyle(theme);
  return {
    ...styles.control
  }
})

export default StyledFullScreenControl;
