import * as React from "react";
import { FunctionComponent, useContext } from "react";

//Custom components
import SidebarContext from "./SidebarContext/SidebarContext";

//Types
import { ISidebarPane } from "@/@types/components/Map/Controls/Sidebar";
import { SidebarContextType } from "@/@types/components/Map/Controls/Sidebar/SidebarContext";

const SidebarPane: FunctionComponent<ISidebarPane> = (props: React.PropsWithChildren<ISidebarPane>) => {
  const sidebarContext = useContext(SidebarContext) as SidebarContextType;

  const { id } = props;

  const isActive = sidebarContext.activePane === id;
  return isActive ? (
    <div className="sidebar-pane active" id={id}>
      {props.children}
    </div>
  ) : null;
};

export default SidebarPane;
