import ExcelJS from 'exceljs';
import dataControllerSubModel from "@/lib/dataControllerSubModel";
import moment, { Moment } from "moment";
import { TFunction } from "i18next";

import radio from "@/models/submodels/radio";
import checkbox from "@/models/submodels/checkbox";
import picker from "@/models/submodels/picker";

import { formats } from "@/lib/formats";
import fileDownload from "js-file-download";

import { PickerItem } from "@/@types/controls/controls";
import { IExportObj } from "@/@types/lib/exportExcel";
import { IFieldPickerModel, FieldAny } from "@/@types/models/model";
import { ISubModelsWithData } from "@/@types/ui/Table";
import { DCFieldValue, DCRecord } from "@/@types/lib/dataController";
import { IDataControllerSub } from "@/@types/lib/dataControllerSub";
import { PickerItemValue } from "@/@types/models/model";

export {
  exportTableToExcel,
  // exportGraphToExcel
};

// function exportGraphToExcel({graphData, filename, t}) {
//   const workbook = new ExcelJS.Workbook();
//   const worksheet = workbook.addWorksheet('Data', { views: [{ xSplit: 1, ySplit: 1 }] });
//   ;

//   const idKey = "id";

//   const columns = graphData.labels.map((label,i) => {
//     return {
//       header: label,
//       key: "col-" + i,
//       width: 20
//     }
//   })
//   columns.unshift({
//     header: "ID",
//     key: idKey,
//     width: 40
//   })

//   worksheet.columns = columns;

//   const ts = moment();
//   const filenameT = `${t(filename)}_${ts.format("YYYYMMDD_HHMM")}.xlsx`

//   graphData.series.forEach(s => {
//     const newRecord = {};
//     newRecord[idKey] = t(s.meta);
//     s.value.forEach((count, i) => {
//       newRecord["col-" + i] = count;
//     })
//     worksheet.addRow(newRecord)
//   })

//   workbook.xlsx.writeBuffer().then(data => {
//     const file = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }, filenameT);
//     fileDownload(file, filenameT);
//   });

// }

function exportTableToExcel ({records, fields, filename, t}: IExportObj): void {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Data', { views: [{ xSplit: 1, ySplit: 1 }] });

  const columns = fields.map(field => {
    return {
      header: t(field.ttoken),
      key: field.source,
      width: field.width
    }
  })

  const fieldsWithModel = fields.filter(
    (f) => ["picker", "checkbox", "radio", "boolean", "active", "iconStatus"].indexOf(f.type) !== -1 && f.hasOwnProperty('subModel')
  ) as Array<IFieldPickerModel>;
  const fieldSubModels: ISubModelsWithData = {};
  const fieldPromises = fieldsWithModel.map(f => {
    const modelType =
      f.type === "picker"
        ? picker
        : f.type === "radio"
        ? radio
        : f.type === "checkbox"
          ? checkbox
          : null;
    if (modelType !== null) {
      const newSubModel = new dataControllerSubModel(modelType, f);
      Object.assign(fieldSubModels, {[f.source]: newSubModel});
      return newSubModel.GetData();
    } else {
      return new Promise((resolve, reject) => reject());
    }
  });

  worksheet.columns = columns.map(c => Object.assign({}, c, { width: 50 }));

  console.log(records)

  const ts = moment();
  const filenameT = `${t(filename)}_${ts.format("YYYYMMDD_HHMM")}.xlsx`

  if (fieldPromises.length > 0) {
    Promise.all(fieldPromises).then(data => {
      records.forEach(record => {
        const newRecord = {};
        fields.forEach(field => {
          const val = record[field.source]
          const sModel = fieldSubModels[field.source];
          const value = getData(field, sModel, val, t);
          Object.assign(newRecord, {[field.source]: value});
        })
        worksheet.addRow(newRecord)
      })

      workbook.xlsx.writeBuffer().then(data => {
        const file = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        fileDownload(file, filenameT);
      });
    })
  } else {
    records.forEach(record => {
      const newRecord = {};
      fields.forEach(field => {
        const val = record[field.source]
        const value = getData(field, null, val, t);
        Object.assign(newRecord, {[field.source]: value});
      })
      worksheet.addRow(newRecord)
    })

    workbook.xlsx.writeBuffer().then(data => {
      const file = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      fileDownload(file, filenameT);
    });
  }

}

function getData(field: FieldAny, sModel: IDataControllerSub | null, val: DCFieldValue, t: TFunction): string {
  if (val === null || null === undefined) return "";
  const { type } = field;
  switch(type) {
    case "date":
      return val ? moment.utc(val as Moment).local().format(formats.date) : "";
    case "datetime":
      return val ? moment.utc(val as Moment).local().format(formats.datetime) : "";
    case "boolean":
    case "radio":
      if (sModel) {
        if (val === null || val === undefined) {
          return '';
        } else {
          const rec = sModel.records.find(x => x.value === val);
          return rec ? t(rec.label) : "";
        }
      } else {
        const { items } = field;
        if (items) {
          const ind = items.values.indexOf(val as PickerItemValue);
          const valueLabel = t(items.labels[ind]);
          return valueLabel;
        } else {
          return val as string;
        }
      }
    case "checkbox":
      if (Number.isInteger(val)) {
        if (sModel) {
          const rec = sModel.records.find(x => x.value == val);
          return rec ? t(rec.label) : "";
        }
        const { items } = field;
        if (!items) return "";
        const codes = val.toString(2).split("").reverse();
        // return codes.map((x,i) => t(items.labels[items.values.indexOf(Math.pow(2,i))])).join(', ');
        const { labels, values } = items;
        return codes.map((x, i) => {
          const ind = values.indexOf(Math.pow(2,i));
          if (!ind) return null;
          return t(labels[ind]);
        }).filter(x => x !== null).join(', ');

      } else if (Array.isArray(val)) {
        if (sModel) {
          return val.map(x => {
            const rec = sModel.records.find(f => f.value === x/* || f.value === x.value*/);
            return rec ? t(rec.label) : "";
          }).filter(v => v !== "").join(', ');
        } else {
          return (val as Array<PickerItem>).map(x => x.value).join(', ');
        }

      } else  {
        return `${val}`;
      }
    case "picker":
      if (sModel) {
        if (val === null || val === undefined) {
          return '';
        } else {
          const rec = sModel.records.find(x => x.value === val);
          return rec ? t(rec.label) : "";
        }
      }
      else {
        const { items } = field;
        if (items) {
          const ind = items.values.indexOf(val as string);
          const valueLabel = t(items.labels[ind]);
          return valueLabel;
        } else {
          return `${val}`;
        }
      }
  }
  return val as string;
}
