import React, { FunctionComponent, useContext, useEffect, useRef } from "react";

//MUI
import { styled } from "@mui/material/styles";

//OpenLayers
import OlControl from "ol/control/Control";
import OlBaseLayer from "ol/layer/Base";

//Custom components
import BaseLayerSwitcherClass from "./baseLayerSwitcherClass";
import BaseLayerSwitcherUI from "./BaseLayerSwitcherUI";
import MapContext from "@/components/Map/MapContext";
import { findControl, getDefinedOptions, getEvents } from "@/lib/olHelpers";
import makeMUIControlStyle from "@/components/Map/Controls/makeMUIControlStyle";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { BaseLayerDefinition } from "@/@types/services/mapService";


export type BaseLayerSwitcherProps = {
  className?: string
  layers: Array<OlBaseLayer>
}

const BaseLayerSwitcherControl: FunctionComponent<BaseLayerSwitcherProps> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;;

  const { className, layers } = props;

  const id = "base-layer-switcher";

  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (elementRef.current) {

      const options = {
        element: elementRef.current,
        target: undefined
      }

      let allOptions = {}
      Object.assign(allOptions, options, props);
      let definedOptions = getDefinedOptions(allOptions);

      const events = {};

      let control = new BaseLayerSwitcherClass(definedOptions);
      control.set("id", id);

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, BaseLayerSwitcherClass);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control as OlControl);
      } else {
        mapContext.initOptions.controls.push(control as OlControl);
      }

      let olEvents = getEvents(events, props);
      for (let eventName in olEvents) {
        //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
        control.on(eventName, olEvents[eventName]);
      }

    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, BaseLayerSwitcherClass);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  return (
    <BaseLayerSwitcherUI layers={layers} ref={elementRef} className={className}/>
    );
};

const StyledBaseLayerSwitcherControl = styled(BaseLayerSwitcherControl)(({theme}) => {
  const styles = makeMUIControlStyle(theme);
  return {
    ...styles.control
  }
})

export default StyledBaseLayerSwitcherControl;
