import React, { Fragment, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import clsx from "clsx";

//Material-UI Core Components
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

//Material-UI Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

//Custom Components
import MapCard from "@/ui/MapCard/MapCard";
import MapCardHeader from "@/ui/MapCard/MapCardHeader";
import MapCardAvatar from "@/ui/MapCard/MapCardAvatar";
import MapCardContent from "@/ui/MapCard/MapCardContent";
import dataController from "@/lib/dataController";
import MapCardHeaderButtonClose from "@/ui/MapCard/MapCardHeaderButtonClose";

import { GridContainer, GridItem } from "@/ui/Grid";

// import { dataService } from "@/services/dataService";
import FormContent from "@/components/FormContent";

import modelData from "@/models/data";
import modelPOI from "@/models/poi";
import modelAdrese from "@/models/adrese";

//Types
import { GSInfoCardProps } from "@/@types/views/GIS/map";

function GSInfoCard(props: GSInfoCardProps) {
  const { t } = useTranslation();

  const initialRecod = props.featureData.record ? props.featureData.record : props.featureData.feature.properties;

  const [record, setRecord] = useState(initialRecod);
 
  const { featureData, onClose } = props;

  const { type } = featureData;

  const getModel = () => {
    switch (type) {
      case "poi":
        return modelPOI;
      case "kb":
        return modelAdrese;
      default:
        return modelData;
    }
  };

  const model = getModel();

  const dc = new dataController(model);

  const faIconClass = "fas fa-info ";

  useEffect(() => {
    if (featureData) {
      if (featureData.record) {
        setRecord(featureData.record);
      } else if (featureData.feature) {
        setRecord(featureData.feature.properties);
      }
    }
    setRecord;
  }, [featureData]);

  const form = "default";
  const title = record.naziv ? record.naziv as string : type ? t("map:layer." + type) : "Info";

  return (
    <Fragment>
      <MapCard
        sx={{
          backgroundColor: "background.default",
          minWidth: "400px"
        }}
      >
        <MapCardHeader
          title={title}
          subheader={record.id ? t("common.id") + ": " + record.id : undefined}
          subheaderTypographyProps={{ color: "initial" }}
          avatar={<MapCardAvatar ariaLabel="info" icon={<i className={faIconClass}></i>} />}
          action={<MapCardHeaderButtonClose onClick={onClose} />}
        />
        <MapCardContent>
          <GridContainer spacing={0}>
            <GridItem xs={12} container>
              {/* <Form dc={dc} mode="view" form={form} record={record}> */}
                <GridContainer spacing={0}>
                <FormContent
                  // dc={dc}
                  // form={form}
                  fields={dc.getFormFields(form)}
                  fieldNames={dc.getFormFieldsNames(form)}
                  columns={1}
                  mode="simpleView"
                  record={record}
                />
                </GridContainer>
              {/* </Form> */}
            </GridItem>
            {/* <GridItem xs={6}>Image Placeholder</GridItem> */}
          </GridContainer>
        </MapCardContent>
      </MapCard>
    </Fragment>
  );
}

export default GSInfoCard;
