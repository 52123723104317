import React, { FunctionComponent, useState, useRef } from "react";
import DialogContext from "@/ui/DialogContext/DialogContext";

//Types
import { IDialogProvider } from "@/@types/ui/DialogContext";
import { DialogProviderState } from "@/@types/ui/DialogContext";
import ConfirmDialog, { ConfirmDialogOptions } from "@/ui/Dialog/ConfirmDialog/ConfirmDialog";

export type ConfirmResult = {
  confirmed?: boolean;
  canceled?: boolean;
  error?: boolean;
};

const DialogProvider: FunctionComponent<IDialogProvider> = (props: React.PropsWithChildren<IDialogProvider>) => {
  const { children } = props;

  const [providerState, setProviderState] = useState<DialogProviderState>({
    component1: null,
    props1: {},
    component2: null,
    props2: {}
  });

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogOptions, setConfirmDialogOptions] = useState<ConfirmDialogOptions | null>(null);

  const awaitingPromiseRef = useRef<{
    resolve: (r: ConfirmResult) => void;
    reject: (r: ConfirmResult) => void;
  }>();

  const showConfirmDialog = (options: ConfirmDialogOptions) => {
    setConfirmDialogOptions(options);
    setConfirmDialogOpen(true);
    return new Promise<ConfirmResult>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleConfirmClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve({ canceled: true });
      //can also be .reject but then we have to catch this in useFormController
    }

    setConfirmDialogOpen(false);
  };

  const handleConfirmSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve({ confirmed: true });
    }

    setConfirmDialogOpen(false);
  };

  const showDialog = (component: FunctionComponent, props = {}, level = 1) => {
    if (level === 1) {
      setProviderState({
        component1: component,
        props1: props,
        component2: null,
        props2: {}
      });
    } else if (level === 2) {
      setProviderState((prevState) => {
        return {
          component1: prevState?.component1,
          props1: prevState?.props1,
          component2: component,
          props2: props
        };
      });
    }
  };

  const hideDialog = (level = 1) => {
    if (level === 1) {
      setProviderState((prevState) => {
        return {
          component1: null,
          props1: {},
          component2: null,
          props2: {}
        };
      });
    } else if (level === 2) {
      setProviderState((prevState) => {
        return {
          component1: prevState.component1,
          props1: prevState.props1,
          component2: null,
          props2: {}
        };
      });
    }
  };

  const providerValue = {
    component1: providerState?.component1,
    component2: providerState?.component2,
    props1: providerState?.props1,
    props2: providerState?.props2,
    showDialog,
    hideDialog,
    showConfirmDialog
  };

  return (
    <>
      <DialogContext.Provider value={providerValue} children={children} />
      <ConfirmDialog
        open={confirmDialogOpen}
        onSubmit={handleConfirmSubmit}
        onCancel={handleConfirmClose}
        {...confirmDialogOptions}
      />
    </>
  );
};

export default DialogProvider;
