import MapPage from "@/views/GIS/MapPage/MapPage";

//Types
import { RouteType } from "@/@types/common";

const publicRoutes: RouteType[] = [
  { path: "/", name: "Map", component: MapPage },
];

export default publicRoutes
