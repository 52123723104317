import * as React from "react";
import { FunctionComponent, useContext, useEffect } from "react";

//OpenLayers
import OlLayerTile from "ol/layer/Tile";
import { Options as LayerTileOptions } from "ol/layer/BaseTile";
import OlSourceOSM from "ol/source/OSM";
import OlSourceStamen from "ol/source/Stamen";
import OlSourceTileWMS, { Options as WMSOptions } from "ol/source/TileWMS";
import OlSourceWMTS from "ol/source/WMTS";
import OlSourceXYZ from "ol/source/XYZ";
import Map from "ol/Map";
import BaseLayer from "ol/layer/Base";

//Custom components
import MapContext from "@/components/Map/MapContext";
import GroupLayerContext from "./GroupLayer/GroupLayerContext";
import { findLayer, getDefinedOptions, getEvents } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { GroupLayerContextType } from "@/@types/components/Map/Layers/GroupLayer";
import { TileLayerProps, OlLayerTileOptions } from "@/@types/components/Map/Layers/layerTile";
import { OlSourceTileWMSOptions } from "@/@types/components/Map/Layers/sourceTileWMS";

const idKey = "id";
const titleKey = "title";
const typeKey = "type";
const stamenKey = "stamen";

const TileLayer: FunctionComponent<TileLayerProps> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;
  const parentLayerContext = useContext(GroupLayerContext) as GroupLayerContextType;

  let layer = undefined;

  const wmsLayers = props.wms?.params?.LAYERS;
  const wmsStyles = props.wms?.params?.STYLES;

  const options: OlLayerTileOptions = {
    className: undefined,
    opacity: undefined,
    visible: undefined,
    extent: undefined,
    zIndex: undefined,
    minResolution: undefined,
    maxResolution: undefined,
    minZoom: undefined,
    maxZoom: undefined,
    preload: undefined,
    source: undefined,
    map: undefined,
    useInterimTilesOnError: undefined,
    properties: undefined
  };

  const wmsOptions: OlSourceTileWMSOptions = {
    attributions: undefined,
    cacheSize: undefined,
    crossOrigin: undefined,
    params: undefined,
    gutter: undefined,
    hidpi: undefined,
    projection: undefined,
    reprojectionErrorThreshold: undefined,
    tileClass: undefined,
    tileGrid: undefined,
    serverType: undefined,
    tileLoadFunction: undefined,
    url: undefined,
    urls: undefined,
    wrapX: undefined,
    transition: 0
  };

  const events = {
    change: undefined,
    "change:extent": undefined,
    "change:maxResolution": undefined,
    "change:maxZoom": undefined,
    "change:minResolution": undefined,
    "change:minZoom": undefined,
    "change:opacity": undefined,
    "change:preload": undefined,
    "change:source": undefined,
    "change:useInterimTilesOnError": undefined,
    "change:visible": undefined,
    "change:zIndex": undefined,
    error: undefined,
    postrender: undefined,
    prerender: undefined,
    propertychange: undefined
  };

  const getSource = () => {
    if (props.source) {
      return props.source;
    } else if (props.wms) {
      let allWMSOptions = Object.assign(wmsOptions, props.wms);
      //@ts-ignore TODO:  Property 'params' is missing in type '{}' but required in type 'Options'
      let definedWMSOptions: WMSOptions = getDefinedOptions(allWMSOptions);

      return new OlSourceTileWMS(definedWMSOptions);
    } else if (props.wmts) {
      return new OlSourceWMTS(props.wmts);
    } else if (props.tms) {
      return new OlSourceXYZ(props.tms);
    } else if (props.stamen) {
      return new OlSourceStamen({
        layer: props.stamen
      });
    } else {
      return new OlSourceOSM();
    }
  };

  // console.log('render layer', layer, props);

  useEffect(() => {
    // console.log('tile layer effect called');
    let allOptions = Object.assign(options, props);
    //@ts-ignore
    let definedOptions = getDefinedOptions(allOptions);
    //@ts-ignore TODO:  Property 'source' does not exist on type 'object'
    definedOptions.source = definedOptions.source || getSource();

    layer = new OlLayerTile(definedOptions);
    if (props.id) {
      layer.set(idKey, props.id);
    }
    if (props.title) {
      layer.set(titleKey, props.title);
    }
    if (props.type) {
      layer.set(typeKey, props.type);
    }
    if (props.zIndex) {
      layer.setZIndex(props.zIndex);
    }

    if (parentLayerContext && parentLayerContext.exists && parentLayerContext.childLayers) {
      parentLayerContext.childLayers.push(layer);
    } else if (mapContext.map) {
      // const mapLayers = mapContext.map.getLayers();
      // mapLayers.getArray().find(x => x instanceof OlLayerTile && x.get(idKey) === props.id);
      const mapLayer = findLayer(mapContext.map, props.id);

      if (mapLayer) {
        // context.updateMap({type: "removeLayer", layer: mapLayer});
        // console.log('remove layer', mapLayer);
        mapContext.map.removeLayer(mapLayer);
      }
      // context.updateMap({type: "addLayer", layer: layer});
      // console.log('add layer', layer);
      mapContext.map.addLayer(layer);
    } else {
      //@ts-ignore TODO: Cannot find what type openlayers wants for layers
      mapContext.initOptions.layers.push(layer);
    }

    let olEvents = getEvents(events, props);
    for (let eventName in olEvents) {
      //@ts-ignore TODO: Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange" | "change:extent" | "change:maxResolution" | "change:maxZoom" | "change:minResolution" | "change:minZoom" | "change:opa
      // city" | "change:visible" | ... 5 more ... | "postrender")[]'
      layer.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (mapContext.map) {
        const mapLayer = findLayer(mapContext.map, props.id);
        if (mapLayer) {
          // console.log('unmounting TileLayer, removing mapLayer', props.id, mapLayer)
          mapContext.map.removeLayer(mapLayer);
        }
      }
    };

    // console.log('layer Tile mounted', layer);
  }, []);

  useEffect(() => {
    if (mapContext.map) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setExtent(props.extent);
    }
  },[props.extent])

  useEffect(() => {
    if (mapContext.map && props.map) {
      const mapLayer = findLayer(mapContext.map, props.id);
      if (mapLayer && mapLayer instanceof OlLayerTile) {
        mapLayer.setMap(props.map);
      }
    }
  }, [props.map])

  useEffect(() => {
    if (mapContext.map && props.maxResolution !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setMaxResolution(props.maxResolution);
    }
  },[props.maxResolution])

  useEffect(() => {
    if (mapContext.map && props.maxZoom !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setMaxZoom(props.maxZoom);
    }
  },[props.maxZoom])

  useEffect(() => {
    if (mapContext.map && props.minResolution !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setMinResolution(props.minResolution);
    }
  },[props.minResolution])

  useEffect(() => {
    if (mapContext.map && props.minZoom !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setMinZoom(props.minZoom);
    }
  },[props.minZoom])

  useEffect(() => {
    if (mapContext.map && props.opacity !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setOpacity(props.opacity);
    }
  },[props.opacity])

  useEffect(() => {
    if (mapContext.map && props.preload !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      if (mapLayer && mapLayer instanceof OlLayerTile) {
        mapLayer.setPreload(props.preload);
      }
    }
  },[props.preload])

  useEffect(() => {
    if (mapContext.map && props.properties !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setProperties(props.properties, true);
    }
  },[props.properties])

  useEffect(() => {
    if (mapContext.map && props.source !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      if (mapLayer && mapLayer instanceof OlLayerTile) {
        mapLayer.setSource(props.source);
      }
    }
  },[props.source])

  useEffect(() => {
    if (mapContext.map && props.useInterimTilesOnError !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      if (mapLayer && mapLayer instanceof OlLayerTile) {
        mapLayer.setUseInterimTilesOnError(props.useInterimTilesOnError);
      }
    }
  },[props.useInterimTilesOnError])

  useEffect(() => {
    if (mapContext.map) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setVisible(props.visible ? true: false);
    }
  }, [props.visible]);

  useEffect(() => {
    if (mapContext.map && props.visible !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setVisible(props.visible);
    }
  }, [props.visible]);

  useEffect(() => {
    if (mapContext.map && props.zIndex !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setZIndex(props.zIndex);
    }
  }, [props.zIndex]);

  useEffect(() => {
    if (mapContext.map) {
      const mapLayer = findLayer(mapContext.map, props.id);
      if (mapLayer && mapLayer instanceof OlLayerTile) {
        const src = (mapLayer as OlLayerTile<OlSourceTileWMS>).getSource();
        if (src) {
          src.updateParams({
            ...src.getParams(),
            LAYERS: wmsLayers,
            STYLES: wmsStyles
          });
        }
      }
    }
  }, [wmsLayers, wmsStyles]);

  useEffect(() => {
    if (mapContext.map && props.title !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.set("title", props.title, true);
    }
  }, [props.title]);



  return null;
};

export default TileLayer;
