import * as React from "react";
import { useContext, useEffect, FunctionComponent } from "react";

//OpenLayers
import OlLayerGroup from "ol/layer/Group";
import OlCollection from "ol/Collection";
import OlBaseLayer from "ol/layer/Base";

//Custom components
import MapContext from "@/components/Map/MapContext";
import GroupLayerContext from "./GroupLayerContext";
import { getDefinedOptions, getEvents } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IGroupLayer } from "@/@types/components/Map/Layers/GroupLayer";
import TileLayer from "ol/layer/Tile";
import TileSource from "ol/source/Tile";
import { EventsKey } from "ol/events";

const GroupLayer: FunctionComponent<IGroupLayer> = (props: React.PropsWithChildren<IGroupLayer>) => {
  const mapContext = useContext(MapContext) as MapContextType;

  //@ts-ignore TODO: Variable 'childLayers' implicitly has type 'any[]' in some locations where its type cannot be determined.
  const childLayers: Array<OlBaseLayer> = [];

  let layer = undefined;

  const options = {
    opacity: undefined,
    visible: undefined,
    extent: undefined,
    zIndex: undefined,
    minResolution: undefined,
    maxResolution: undefined,
    minZoom: undefined,
    maxZoom: undefined
  };

  const idKey = "id";
  const titleKey = "title";
  const foldKey = "fold";

  const events = {
    change: undefined,
    "change:extent": undefined,
    "change:layers": undefined,
    "change:maxResolution": undefined,
    "change:maxZoom": undefined,
    "change:minResolution": undefined,
    "change:minZoom": undefined,
    "change:opacity": undefined,
    "change:visible": undefined,
    "change:zIndex": undefined,
    error: undefined,
    postrender: undefined,
    prerender: undefined,
    propertychange: undefined
  };

  const hash: string =
    "hash_" +
    (props && Array.isArray(props.children)
      ? props.children.filter((x) => (typeof x === "object" && x !== null && x.hasOwnProperty("key"))).map((x) => (x as React.ReactElement).props.key).join()
      : "");

  useEffect(() => {
    // console.log('ue GroupLayer', props.id, hash);

    let allOptions = Object.assign(options, props);
    let definedOptions = getDefinedOptions(allOptions);

    layer = new OlLayerGroup(definedOptions);
    if (props.id) {
      layer.set(idKey, props.id);
    }
    if (props.title) {
      layer.set(titleKey, props.title);
    }
    if (props.fold) {
      layer.set(foldKey, props.fold);
    }

    const validLayers = childLayers.filter((x) => x !== null);

    layer.setLayers(new OlCollection(validLayers, { unique: true }));

    if (mapContext.map) {
      const mapLayers = mapContext.map.getLayers();
      const mapLayer = mapLayers.getArray().find((x) => x instanceof OlLayerGroup && x.get(idKey) === props.id);

      if (mapLayer) {
        console.log("remove layer", mapLayer, props.id);
        mapContext.map.removeLayer(mapLayer);
      }
      console.log("add layer", layer, props.id);
      mapContext.map.addLayer(layer);
    } else {
      mapContext.initOptions.layers.push(layer);
    }

    let olEvents = getEvents(events, props);
    if (olEvents !== null) {
      for (let eventName in olEvents) {
        const kEventName = eventName as keyof typeof events;
        //@ts-ignore
        layer.on(kEventName, olEvents[eventName]);
      }
    }
  }, [hash]);

  // console.log('r GroupLayer', hash, props.children);

  return (
    <GroupLayerContext.Provider value={{ exists: true, childLayers: childLayers }}>
      {props.children}
    </GroupLayerContext.Provider>
  );
};

export default GroupLayer;
